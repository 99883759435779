<template>
  <v-app id="inspire">
    <div class="home-container">
      <SimpleFlexRow :classes="{ 'base-row': true, 'nowrap': true }">
        <SimpleFlexColumn :classes="{ 'drawer-container': true }">
          <div class="drawer" v-if="!communityManagerScreen">
            <div class="pic">
              <router-link to="/profile/edit"><img :src="(userData.profile && userData.profile.pic )? userData.profile.pic : '/static/communities/user-no-pic.png'" /></router-link>
              <div class="user-title">
                <h2>{{ userData.username || "User" }}</h2>
                <router-link to="/profile/edit">Edit Profile</router-link>
              </div>
            </div>

            <div class="menu">
              <MenuItem icon="/static/icons/home.png" label="Dashboard" link="/" />
              <MenuItem v-if="demo" icon="/static/icons/face.png" label="People"  link="/people" />
              <MenuItem icon="/static/icons/community.png" label="Community" link="/communities" />
              <MenuItem icon="/static/icons/calendar.png" label="Events" link="/events" />
              <MenuItem v-if="demo" icon="/static/icons/book.png" label="Courses" link="/courses" />
              <MenuItem icon="/static/icons/logout.png" label="Log Out" link="/logout" />
            </div>

          </div>
          <div class="drawer" v-if="communityManagerScreen">
            <div class="pic">
              <img :src="pic? pic : '/static/communities/user-no-pic.png'" />
              <div class="community-title">
                <h2>{{ name? name : "Community"}}</h2>
                <a target="_blank" :href="link">Open community page</a>
              </div>
            </div>
            <div class="menu">
              <MenuItem v-if="demo" icon="/static/icons/home.png" label="Dashboard" :link="'/community-manager/' + communitySlug + '/dashboard'" />
              <MenuItem icon="/static/icons/community.png" label="Manage Community" :link="'/community-manager/' + communitySlug + '/edit'" />
              <MenuItem icon="/static/icons/forms.png" label="Customize Forms" :link="'/community-manager/' + communitySlug + '/forms'" />
              <MenuItem icon="/static/icons/calendar.png" label="Manage Events" :link="'/community-manager/' + communitySlug + '/events'" />
              <MenuItem icon="/static/icons/forms.png" label="AI Transcriber" :link="'/community-manager/' + communitySlug + '/ai-transcriber'" />
              <MenuItem icon="/static/icons/logout.png" label="Log Out" link="/logout" />
            </div>
          </div>
        </SimpleFlexColumn>
        <SimpleFlexColumn :classes="{ 'content': true }" >
          <!-- Content -->
          <router-view
            @set-pic-name="setPicName"
          ></router-view>
          <p class="footer-highlight-row"><a href="/policies/">Privacy Policy</a></p>
        </SimpleFlexColumn>
      </SimpleFlexRow>
    </div>
  </v-app>
</template>
<script>
// Fetch compatibility
import 'whatwg-fetch'
import MenuItem from '../components/MenuItem.vue'
import SimpleFlexRow from '../components/SimpleFlexRow.vue'
import SimpleFlexColumn from '../components/SimpleFlexColumn.vue'


export default {
  name: 'Home',
  components: {
    MenuItem, SimpleFlexRow, SimpleFlexColumn
  },
  props: {
    userData: Object
  },
  data () {
    return {
      contentTitle: "",
      demo: false,
      pic: null,
      name: null,
      communitySlug: null,
      user: {
        profile: {
          location: "",
          pic: null,
          description: "",
          community_favs: []
        },
        username: null,
        badges: [],
        my_communities: [],
      },
      communityManagerScreen: false,
    }
  },
  computed: {
    link() {
      if (this.$route.path.startsWith('/community-manager/')) {
        return '/communities/' + this.$route.params.slug
      }
      return ''
    }
  },
  methods: {
    async handleRoute (route) {
      if (route.path.startsWith('/community-manager/')) {
        this.communityManagerScreen = true
        this.communitySlug = route.params.slug
      }
      else {
        this.communityManagerScreen = false
      }
    },
    setPicName (pic, name) {
      this.pic = pic
      this.name = name
    }
  },
  watch: {
    $route (to) {
      this.handleRoute(to)
    }
  },
  created () {
    let header = document.getElementById("header")
    header.classList.add("navbar-home")
    this.handleRoute(this.$route)

  }
}
</script>
<style>
.home-container {
  position: relative;
}
.drawer-container {
  background: #222222;
  max-width: 280px;
  padding: 0px 30px;
  padding-right: 10px;
}
.base-row {
  min-height: 100vh;
}
.pic img {
  width: 55px;
  height: 55px;
  object-fit: cover;
  border-radius: 150px;
  background: #999;
  float:left;
}
.pic .user-title, .pic .community-title {
  margin-left: 70px;
  color: white;
  font-weight: bold;
}
.user-title h2 {
  font-size: 22px;
  font-weight: bold;
}
.community-title h2 {
  font-size: 20px;
  font-weight: bold;
  padding-top: 10px;
}
.user-title a {
  text-decoration: underline;
  font-weight: normal;
}
.pic {
  margin-top: 20px;
  min-height: 62px;
}
.menu {
  margin-top: 40px;
}
.menu-item {
  color: white;
}
.home-container .content {
  margin: 0;
  padding-left: 20px;
  max-width: 1400px;
  min-width: 0;
}
.home-container .content .container-fluid {
  padding: 0px;
}

@media (max-width: 900px) {
  .drawer-container {
    max-width: 40px;
    padding: 0px 10px;
  }
  .pic {
    display: none;
  }
}

@media (max-width: 500px) {
  .home-container .content {
    padding: 10px 0px 0px 10px;
  }
  .container-fluid {
    padding-left: 5px;
  }
  .main-content .content{
    padding-left: 7px;
  }
}

.v-application a {
  color: inherit !important;
}

a {
  color: inherit;
  text-decoration: inherit;
  cursor: pointer;
}

</style>
