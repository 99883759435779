<template>
  <div
      :class="{ 'menu-item': true, 'active': active, ...classes }"
      @click="select"
  >
    <img :src="icon" /><label>{{label}}</label>
  </div>
</template>
<script>
export default {
  name: 'MenuItem',
  props: {
    activeItem: String,
    icon: String,
    label: String,
    link: String,
    classes: Object
  },
  data () {
    return {
    }
  },
  methods: {
    select() {
      if (this.link)
        this.$router.push(this.link).catch(()=>{})
      else
        this.$emit('select', this.label)
    }
  },
  computed: {
    active () {
      if (this.link) {
        return this.$route.path == this.link || this.$route.path.endsWith(this.link)
      }
      else
        return this.label == this.activeItem
    }

  }
}
</script>
<style>
.menu-item {
  opacity: 0.4;
  margin-bottom: 30px;
  cursor: pointer;
}
.menu-item img {
  width: 20px;
  height: 20px;
  cursor: inherit;
}
.menu-item label {
  cursor: inherit;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  vertical-align: middle;
  padding-left: 10px;
}
.menu-item.active {
  opacity: 1;
}


@media (max-width: 900px) {
  .menu-item label {
    display: none;
  }
}

</style>
