import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import VueRouter from 'vue-router'
import Home from './Home.vue'
import {getDocumentData, client} from '../client'
import { ClientPendingActions } from '../pending_actions'

Vue.use(VueRouter);
import VueMatomo from 'vue-matomo';  /// suggested from https://webdeasy.de/en/matomo-vue-js-en/


function parseRecommendations(data) {
  if (!data) return null
  return data.scores.map((item) => {
    let object = data[item.type].find((searchItem) => searchItem.id == item.id )
    return {
      'communitySlug': object.slug,
      'username': object.username,
      'slug': object.slug || object.username,
      'type': item.type,
      'title': (item.type=='community')? object.title: (item.type=='user')? object.first_name: '',
      'pic': (item.type=='community')? object.cover: (item.type=='user')? object.profile.pic || '/static/communities/user-no-pic.png': null,
      'recommendation': item,
      'connectors': (item.type=='community')? data.connectors[object.slug] : null
    }
  }).sort((itemA, itemB) => itemB.recommendation.score-itemA.recommendation.score)
}

if (document.getElementById("home-content")) {
  (async () => {
    const homeData = getDocumentData("home-data");
    var userData = await client.get("/api/account/" + homeData.username + "/")
    var dashboardData = await client.get("/api/account/" + homeData.username + "/dashboard/")
    dashboardData.recommendations = parseRecommendations(dashboardData.recommendations)
    var ProfileEditor = () => import('./ProfileEditor.vue')
    var Dashboard = () => import('./Dashboard.vue')
    var LogOut = () => import('./LogOut.vue')
    var EventsHome = () => import('./EventsHome.vue')
    var Community = () => import('./UserCommunities.vue')
    var Matcher = () => import('./Matcher.vue')
    var CommunityManager = () => import('../community/CommunityManager.vue')

    var ContentViewer = () => import('../community/ContentViewer.vue')
    const router = new VueRouter({
      routes: [
        { path: '', component: Dashboard, props: { userData: userData, dashboardData: dashboardData } },
        { path: '/logout', component: LogOut },
        { path: '/events', component: EventsHome, props: { userData: userData } },
        { path: '/matches/:type?/:slug?',
          component: Matcher,
          props: {
            userData: userData,
            matchData: {
              recommendations: dashboardData.recommendations
            }
          },
          name: 'matcher'
        },
        { path: '/matches/:type/:slug/:subset/:username?',
          component: Matcher,
          props: {
            userData: userData,
            matchData: {
              recommendations: dashboardData.recommendations
            }
          },
          name: 'matcher-connectors'
        },
        { path: '/communities', component: Community, props: { userData: userData } },
        { path: '/profile/edit', component: ProfileEditor, props: { userDataInput: userData } },
        { path: '/community-manager/:slug/:page?', component: CommunityManager, props: { userData: userData}, name: 'communityManager' },
        { path: '/community-manager/:slug/content/:blog_slug', component: CommunityManager, props: { userData: userData}, name: 'communityContentEdit' },
        { path: '/community-manager/:slug/events', component: CommunityManager, props: { editable: true }, name: 'communityEvents' },
        { path: '/community-manager/:slug/events/new', component: CommunityManager, props: { editable: true }, name: 'communityEventsNew' },
        { path: '/community-manager/:slug/events/:evslug', component: CommunityManager, props: { editable: true }, name: 'communityEventDetail' },
        { path: '/community-manager/:slug/forms', component: CommunityManager, props: { editable: true }, name: 'communityForms' },
        { path: '/community-manager/:slug/ai-transcriber/', component: CommunityManager, props: {}, name: 'communityAITranscriber' },
        { path: '/community-manager/:slug/ai-transcriber/new', component: CommunityManager, props: {}, name: 'communityAITranscriberNew' },
        { path: '/community-manager/:slug/ai-transcriber/:id', component: CommunityManager, props: {}, name: 'communityAITranscriberDetail' },

        { path: '/content/:slug/:content_slug', component: ContentViewer, props: {}, name: 'contentViewer' }
      ]
    })

    Vue.use(VueMatomo, {
      host: "https://sfa.storyfaith.com/",
      siteId: 1,
      trackerFileName: 'matomo',
      router: router,
      enableLinkTracking: true,
      requireConsent: false,
      trackInitialView: true,
      disableCookies: false,
      enableHeartBeatTimer: true,
      heartBeatTimerInterval: 15,
      debug: false,
      userId: undefined,
      cookieDomain: undefined,
      domains: undefined,
      preInitActions: []
    });


    new Vue({
      vuetify: vuetify,
      router: router,
      render: h => h(Home, {
        props: {
          userData: userData
        }
      }),
    }).$mount('#home-content')

    var pendingActions = new ClientPendingActions()
    if (pendingActions.actions.length) {
      pendingActions.actions.forEach(async function(item) {
        pendingActions.handleAction(item)
      })
      location.reload()
    }
  })()
}
else if (document.location.hash.startsWith("#/content/")) {
    var parts = document.location.hash.split("/")
    document.location = `${parts[2]}/blog/${parts[3]}`
}

